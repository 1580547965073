<template>
  <div
    class="Question-container"
    v-bind:style="questionContainerStyle"
    @click="slotCardSelected"
  >
    <div class="Question-body">
      <div class="row-div">
        <div class="row-info-div">
          <div class="title-div">
            <div class="row py-2">
              <div class="pr-1 ml-2">
                <strong>{{ Question.order }}</strong
                >.
              </div>
              <div class="text-left">
                <strong>{{ Question.title }}</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="vl"></div>
        <div class="footer-buttons-div">
          <div class="type-div">
            <strong>{{ questionType ? questionType.value : "-" }}</strong>
          </div>
        </div>
      </div>
      <hr />
      <ImagesContainer
        :Images="topQuestionImages"
        :allows_crud="false"
      ></ImagesContainer>
      <div v-if="show_description && Question.description" class="content-div">
        <p>{{ Question.description }}</p>
      </div>

      <template v-if="canAnswer">
        <StyledTextArea
          class="my-3"
          :disabled="this.evaluation.finished"
          v-model="redaction_question_answer.answer"
          placeholder="Escriba su respuesta aquí..."
          @input="patchAnswer"
        ></StyledTextArea>
      </template>

      <ImagesContainer
        :Images="bottomQuestionImages"
        :allows_crud="false"
      ></ImagesContainer>

      <hr />
      <div class="row-div">
        <div class="row-info-div pt-1">
          <div class="px-2">Puntaje: {{ Question.score }}</div>

          <!-- <div class="px-2">Ponderación {{ Question.weighing }}%</div> -->
        </div>

        <div class="vl"></div>
        <div class="footer-buttons-div"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast, generateUniqueId } from "@/utils/utils";

export default {
  name: "AnswerRedactionQuestion",
  components: {
    ImagesContainer: () => import("@/components/reusable/ImagesContainer"),
    StyledTextArea: () => import("@/components/reusable/StyledTextArea"),
  },
  props: {
    Question: {
      type: Object,
      required: true,
    },
    is_selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show_description: true,
      imageAlignClass: {
        justifyContent: "center",
      },
      redaction_question_answer: {
        id: generateUniqueId(),
        question: this.Question.id,
        student: this.$store.getters.getUser.id,
        score: null,
        answer: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      evaluationInstruments: names.EVALUATION_INSTRUMENTS,
      images: names.IMAGES,
      user: "getUser",
      questionTests: names.QUESTION_TESTS,
      evaluations: names.EVALUATIONS,
      sections: names.SECTIONS,
    }),
    questionTest() {
      return this.questionTests.find(
        (x) => x.id == this.Question.question_test
      );
    },
    evaluation() {
      if (!this.questionTest) return null;
      return this.evaluations.find((x) => x.id == this.questionTest.evaluation);
    },
    section() {
      if (!this.evaluation) return null;
      return this.sections.find((x) => x.id == this.evaluation.section);
    },
    canAnswer() {
      if (!this.section || !this.user) return false;
      const flag = this.section.students.find((x) => x == this.user.id);
      if (flag) return true;
      return false;
    },
    questionType() {
      return this.evaluationInstruments.find(
        (x) => x.id == this.Question.question_type
      );
    },
    questionContainerStyle() {
      return {
        backgroundColor: this.is_selected ? "var(--kl-menu-color)" : "white",
      };
    },
    questionImages() {
      let list = [];
      this.Question.images.forEach((element) => {
        const instance = this.images.find((x) => x.id == element);
        if (instance) list.push(instance);
      });
      return list;
    },
    topQuestionImages() {
      return this.questionImages.filter((x) => x.vertical_align == 1);
    },
    bottomQuestionImages() {
      return this.questionImages.filter((x) => x.vertical_align == 2);
    },
  },
  methods: {
    setSave: function (value) {
      if (value) {
        this.patchAnswer();
      }
    },
    slotCardSelected() {
      this.$emit("selected", this.Question.id);
    },
    fetchRedactionQuestionAnswer() {
      this.$restful
        .Get(
          `/evaluation/redaction-answer/?question=${this.Question.id}&student=${this.user.id}`
        )
        .then((response) => {
          if (response.length > 0) this.redaction_question_answer = response[0];
          if (response.length > 1)
            console.log("RedactionQuestionAnswer = ", response.length);
        });
    },
    patchAnswer() {
      if (!this.evaluation.finished) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (isNaN(this.redaction_question_answer.id))
            this.createRedactionQuestionAnswer();
          else
            this.patchRedactionQuestionAnswer({
              answer: this.redaction_question_answer.answer,
            });
        }, 2000);
      } else {
        toast("No puede guardar la pregunta, si ya finalizo la evaluación");
      }
    },
    patchRedactionQuestionAnswer(item) {
      this.$restful
        .Patch(
          `/evaluation/redaction-answer/${this.redaction_question_answer.id}/`,
          item
        )
        .then((response) => {
          this.redaction_question_answer = response;
          toast("Respuesta guardada.");
        });
    },
    createRedactionQuestionAnswer() {
      this.$restful
        .Post(`/evaluation/redaction-answer/`, this.redaction_question_answer)
        .then((response) => {
          this.redaction_question_answer = response;
          toast("Respuesta guardada.");
        });
    },
  },
  created() {
    this.fetchRedactionQuestionAnswer();
    this.Question.images.forEach((element) => {
      this.$store.dispatch(names.FETCH_IMAGE, element);
    });
    this.$parent.$on("update", this.setSave);
  },
};
</script>

<style scoped>
.Question-container {
  border-radius: 7px;
  margin-top: 5px;
  margin-bottom: 15px;
  box-shadow: 2px 3px 10px #686969;
}
.Question-body {
  background-color: white;
  margin-left: 5px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.header-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.title-div {
  width: 75%;
  text-align: left;
}
.content-div {
  margin: 0.1em 0.5em;
  display: flex;
  flex-direction: column;
  align-items: initial;
}
.row-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.row-info-div {
  width: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.footer-buttons-div {
  width: 29%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.type-div {
  width: auto;
  align-self: center;
  text-align: left;
  padding-right: 10px;
}
.image-div {
  margin: 0.1em;
}
.image-list-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.1em 0.5em;
}
.feedback-div {
  margin: 0px 0.5em;
}
a {
  text-align: left;
  width: min-content;
  min-width: 25%;
  vertical-align: middle;
}
hr {
  background-color: var(--secondary-color);
  height: 1px;
  margin: 0px 0px;
}
.vl {
  border-left: 1px solid var(--secondary-color);
  padding: 0px;
  margin: 0px;
}
</style>